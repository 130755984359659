import CryptoJs from 'crypto-js'
const OSS = require('ali-oss');
let ossClient
let ossAbortMultipartUploadObj = {} // 此对象为了取消固化做的准备
export const ossMultipartUpload = ({ fileHash, accessKey, secretKey, secretToken, bucket, key, file, contentMd5 }, progressCallback) => {
    return new Promise( async (resolve, reject) => {
        ossAbortMultipartUploadObj.size = 0
        ossAbortMultipartUploadObj.key = ''
        ossAbortMultipartUploadObj.uploadId = ''
        try {
            let ossConfig = {
                region: 'oss-cn-beijing', // 这里需要接口传递
                accessKeyId: accessKey,
                accessKeySecret: secretKey,
                stsToken: secretToken,
                bucket: bucket,
                secure: true // 开启https
            }
            ossClient = new OSS(ossConfig)
            // console.log(contentMd5)
            // let result = await ossClient.put(key, file)
            ossAbortMultipartUploadObj.size = file.size
            ossAbortMultipartUploadObj.key = key
            let result = await ossClient.multipartUpload(key, file, {
                parallel: 4,
                partSize: 1 * 1024 * 1024,
                meta: {
                    // 'key': key,
                },
                headers: {
                    // 'key': '12345678',
                    // 'Content-MD5': contentMd5
                },
                progress: function (p, checkpoint, res) {
                    let percentage = (p * 100).toFixed(0)
                    if (p < 1) {
                        ossAbortMultipartUploadObj.uploadId = checkpoint.uploadId
                    }
                    progressCallback && progressCallback({
                        percentage: parseInt(percentage),
                        ossClient
                    })
                    
                },
                // disabledMD5: false
            })
            // console.log(await ossClient.head(key))
            // CryptoJs.enc.Base64.stringify(md5) === contentMd5
            // console.log(result)
            ossAbortMultipartUploadObj = {}
            resolve({})
        } catch (error) {
            reject(error)
        }
        
    })
}
// 此方法暂时不用
export const ossAbortMultipartUpload = () => {
    return new Promise(async (resolve, reject) => {
        try {
            let { size, key, uploadId } = ossAbortMultipartUploadObj
            console.log(size, key, uploadId)
            if (size) {
                if (size > 100) {
                    // console.log(await ossClient.listParts(key, uploadId))
                    // ossClient.cancel()
                    ossClient.abortMultipartUpload(key, uploadId).then(async (result) => {
                        resolve(result)
                        // console.log(await ossClient.listParts(key, uploadId))
                    }).catch((error) => {
                        reject(error)
                    });
                } else {
                    ossClient.cancel()
                    resolve()
                }
            } else {
                resolve()
            }
            
        } catch (error) {
            reject(error)
        }
    })
}                                